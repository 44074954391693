<template>
  <div class="guide-wrap">
    <public-banner :banner-info="bannerInfo" />
    <div class="main container">
      <ul class="integration-guide">
        <li v-for="(item, index) in integrationList" :key="index" class="integration-class">
          <div class="integration-product-img">
            <div class="integration-img" :style="`background: url(${item.nav_image}) center center / cover no-repeat`"></div>
            <h4 v-if="isTitle" class="img-title">{{ item.nav_name }}</h4>
          </div>
          <div class="integration-instr">
            <div v-for="(itemChild, indexChild) in item.download_list" :key="indexChild" class="integration-product center ">
              <h5 class="product-title">
                <span @click="download(itemChild)">
                  {{ itemChild.download_name }}
                </span>
                <a v-if="itemChild.menu === '1' || itemChild.menu === '2'" class="iconfont icon-a-xiazai1x  icon" @click="download(itemChild)"></a>
                <a v-if="itemChild.menu === '3'" class="iconfont icon-a-youjiantou1x icon" @click="download(itemChild)"></a>
              </h5>
              <p class="product-text">{{ itemChild.description }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { mapActions } from 'vuex'
import { getIntegrationGuideApi, getIntegrationDownloadApi } from '@/api/integration.js'
export default {
  components: {
    PublicBanner
  },
  props: {
    // 是否展示标题
    isTitle: {
      type: Boolean,
      default: true
    },
    // 是否展示图标
    isDownload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      integrationList: [],
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      }
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {
    this.getIntegrationGuideList()
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 112 })
      this.bannerInfo.title = data.data.img_title
      this.bannerInfo.img = data.data.img_image
    },
    // 调用guide页面接口
    getIntegrationGuideList() {
      getIntegrationGuideApi().then((res) => {
        this.integrationList = res.data
      })
    },
    // getDownload(down) {
    //   getIntegrationDownloadApi({
    //     id: down
    //   }).then((res) => {
    //     console.log('aaa')
    //   })
    // },

    // 点击下载或者跳转按钮
    download(item) {
      if (item.power === '0') {
        if (item.menu === '3') {
          window.open(item.link, '_blank')
        }
        // 页面跳转或者下载统计的接口，返回参数id
        getIntegrationDownloadApi({
          id: item.download_list_id,
          download_title: item.download_name
        }).then(res => {
          if (res.status === '00' && (item.menu === '1' || item.menu === '2')) {
            window.open(res.data, '_blank')
          }
          if (res.status === '43') {
            this.$message.error(res.message)
          }
        })
        // this.getDownload(item.download_list_id)
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.guide-wrap{
  background-color: #FFFFFF;
  .main{
    margin: 72px auto;
    .integration-guide{
      .integration-class{
        display: flex;
        justify-content:flex-start;
        .shadow(@opacity: 0.1);
        & + .integration-class {
          margin-top: 24px;
        }
        .integration-product-img{
          position: relative;
          width: calc(33% - 6px);
					min-height: 160px;
          .integration-img{
            width: 100%;
            height: 100%;
          }
          .img-title{
            position: absolute;
            left: 38px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 24px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 32px;
            word-break: keep-all;
          }
        }
        .integration-instr{
          display: flex;
          width: 67.6%;
          .integration-product{
            width: 50%;
            margin-left: 16px;
            .product-title{
              font-size: 16px;
              font-weight: 600;
              color: #333333;
              line-height: 21px;
              span {
                cursor: pointer;
              }
            }
            .product-text{
              font-size: 14px;
              color: #606266;
              line-height: 19px;
              margin-top: 16px;
            }
          }
        }
        .center{
          padding: 40px 38px;
        }
        .icon{
          margin-left: 27px;
					font-size: 21px;
        }
        .icon:hover{
          cursor:pointer
        }
      }
    }
  }
}
@media only screen and (max-width:1024px) {
  .guide-wrap{
    .main{
      .integration-guide{
        .integration-class{
          .integration-product-img{
            .img-title{
              font-size: 20px;
            }
          }
          .integration-instr{
            .integration-product{
              .product-title{
                font-size: 14px;
              }
              .product-text{
                font-size: 12px;
                margin-top: 12px;
              }
            }
          }
          .center{
            padding: 24px 16px;
          }
        }
      }
    }
  }
}
//移动端
@media only screen and (max-width: 768px) {
  .guide-wrap{
    .main{
      margin: 36px auto;
      .integration-guide{
        .integration-class{
          flex-wrap: wrap;
          .integration-product-img{
            width: 100%;
            height: 180px;
            .img-title{
              font-size: 22px;
            }
          }
          .integration-instr{
            width: 100%;
            flex-wrap: wrap;
            .integration-product{
              width: 100%;
              margin-left: 0px;
              .product-text{
                line-height: 22px;
              }
            }
          }
          .center{
            padding: 15px 20px;
          }
          .icon{
            margin-left: 16px;
          }
        }
      }
    }
  }
}

</style>
