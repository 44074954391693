<template>
  <div class="product-wrap">
    <public-banner :banner-info="bannerInfo" />
    <div class="product-list container">
      <router-link v-for="(item, index) in productList" :key="index" :to="`/integration/overview?id=${item.nav_id}`" class="product-items">
        <div class="img-box">
          <div class="product-img" :style="`background: url(${item.nav_image}) center center / cover no-repeat`"></div>
        </div>
        <div class="product-title">
          <h4 class="product-name ellipsis">{{ item.nav_name }}</h4>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { getIntegrationProductApi } from '@/api/integration.js'
import { mapActions } from 'vuex'
export default {
  components: {
    PublicBanner
  },
  data() {
    return {
      productList: [],
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      }
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {
    this.getIntegrationProduct()
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 122 })
      this.bannerInfo.title = data.data.img_title
      this.bannerInfo.img = data.data.img_image
    },
    // 调用product页面接口
    getIntegrationProduct() {
      getIntegrationProductApi().then((res) => {
        this.productList = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.product-wrap{
  background: #FFFFFF;
  .product-list{
    display: flex;
    flex-wrap:wrap;
    padding: 72px 0 56px 0;
    .product-items{
      width: calc(33.333% - 11px);
      margin: 0 16px 16px 0;
      .shadow();
      &:nth-child(3n){
        margin-right: 0;
      }
      &:hover{
        .product-title .product-name{
          color: @theme;
        }
      }
      .img-box{
        height: 236px;
        overflow: hidden;
        &:hover{
          .product-img{
            .scale();
          }
        }
        .product-img{
         height: 236px;
         .transition();
        }
      }
      .product-title{
        height: 72px;
        padding: 0 16px;
        .product-name{
          font-size: 18px;
          font-weight: 600;
          color: #303133;
          line-height: 72px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .product-wrap{
    .product-list{
      padding: 36px 0 28px 0;
      .product-items{
        .product-img{
          height: 210px;
        }
        .product-title{
          height: 52px;
          padding: 0 12px;
          .product-name{
            font-size: 16px;
            line-height: 52px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .product-wrap{
    .product-list{
      padding: 36px 0 28px 0;
      .product-items{
        width: calc(50% - 4px);
        margin: 0 8px 8px 0;
        &:nth-child(3n){
          margin-right: 8px;
        }
        &:nth-child(2n){
          margin-right: 0;
        }
        .product-img{
          height: 140px;
        }
        .product-title{
          height: 42px;
          padding: 0 8px;
          .product-name{
            font-size: 14px;
            line-height: 42px;
          }
        }
      }
    }
  }
}

</style>
