import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  integration: '/Integration_protocols/Integration_protocols', // Integration Protocols模块首页
  guide: '/Integration_protocols/Integration_guide', // Dahua Products Integration Guide页面
  product: '/Integration_protocols/product_integration', // Product Integration模块
  download: '/Integration_protocols/hit', // 页面下载或者下载得统计
  singlePage: '/download_center/single_page', // sdk弹窗单页
  sdkDetail: '/download_center/index', // sdk详情页
  hit: '/download_center/hit' // 下载量
}

// Integration Protocols模块首页
export const getIntegrationApi = () => _axios.get(apiUrl.integration)
// Dahua Products Integration Guide页面
export const getIntegrationGuideApi = () => _axios.get(apiUrl.guide)
// Product Integration模块
export const getIntegrationProductApi = (params) => _axios.post(apiUrl.product, params)
// 页面下载或者下载得统计
export const getIntegrationDownloadApi = (params) => _axios.post(apiUrl.download, params)
// sdk弹窗单页
export const singlePageApi = (params) => _axios.get(apiUrl.singlePage + `?id=${params.id}`)
// sdk详情页
export const sdkDetailApi = (params) => _axios.get(apiUrl.sdkDetail + `?id=${params.id}&child=${params.child}&page=${params.page}`)
// 下载量
export const hitApi = (params) => _axios.get(apiUrl.hit + `?id=${params.id}`)
