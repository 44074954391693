<template>
  <div class="partners-wrap">
    <public-banner :banner-info="bannerInfo" :is-search="true" @search="handleSearch" />
    <div v-loading="loading" class="partners-content container" element-loading-text="loading...">
      <div v-for="(item, index) in partnersList" :key="index" class="partners-list">
        <div class="partners-top">
          <h4 class="partners-title">{{ item.menu }}</h4>
          <router-link v-if="item.data.length" :to="`/partners/list?type=${item.dhop}`" class="partners-more">
            {{ LP.lang_view_all }}<i class="iconfont icon-a-jiantouwhite1x arrow"></i>
          </router-link>
        </div>
        <div class="partners-desc">
          {{ item.partners_description }}
        </div>
        <swiper ref="tabSwiper" class="partners-swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(item, index) in item.data" :key="index">
            <div class="swiper">
              <router-link :to="`/partners/introduction?company_name=${item.company_name}`">
                <div class="partners-img" :style="`background: #fff url(${item.company_image}) center center / contain no-repeat`"></div>
                <div class="partners-names">
                  <h5 class="partners-name ellipsis">{{ item.company_name }}</h5>
                </div>
              </router-link>
            </div>
          </swiper-slide>
          <div slot="button-prev" class="swiper-button-prev">
            <span class="iconfont icon-a-zuojiantou1x icon"></span>
          </div>
          <div slot="button-next" class="swiper-button-next">
            <span class="iconfont icon-a-jiantouwhite1x icon"></span>
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import PublicBanner from '@/components/public/PublicBanner.vue'
import { programHomeApi } from '@/api/partners'
import { mapState } from 'vuex'
export default {
  components: {
    swiper,
    swiperSlide,
    PublicBanner
  },
  data() {
    return {
      bannerSwiperOption: {
        autoplay: false, // 自动切换
        loop: false, // 无限循环
        slidesPerView: '4', // 每屏显示个数
        allowTouchMove: true, // 是否可拖动
        navigation: { // 前进后退按钮
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 不同页面断点
        breakpoints: {
          768: {
            slidesPerView: 1
          }
        }
      },
      partnersList: [],
      loading: false
    }
  },
  computed: {
    ...mapState({
      bannerInfo: (state) => state.common.bannerInfo
    })
  },
  created() {
    this.getBanner()
  },
  mounted() {
    this.getProgramHome()
  },
  methods: {
    // 获取轮播图
    async getBanner() {
      this.$store.dispatch('common/getBannerImg', { num: 62 })
    },
    // 获取program首页数据
    getProgramHome(partnerName) {
      this.loading = true
      programHomeApi({
        partner_name: partnerName
      }).then(res => {
        this.partnersList = res.data
        this.loading = false
      })
    },
    // 搜索
    handleSearch(keywords) {
      this.getProgramHome(keywords)
    }
  }
}
</script>

<style lang="less" scoped>
.partners-wrap {
  background-color: @background;
  overflow: hidden;
  /deep/.public-banner .banner-bg .banner-info .banner-search .el-input__inner{
    border-radius: 36px;
  }
  .partners-content {
    padding: 72px 0 62px 0;
    .partners-list {
      & + .partners-list {
        margin-top: 72px;
      }
      .partners-top {
        position: relative;
        .partners-title{
          font-size: 32px;
          font-weight: 600;
          color: #303133;
          line-height: 48px;
        }
        .partners-more{
          position: absolute;
          top:8px;
          right: 0px;
          font-size: 16px;
          font-weight: normal;
          color: #303133;
          line-height: 24px;
          text-decoration: underline;
          font-style: italic;
          &:hover{
            color: @theme !important;
          }
        }
        .arrow{
          display: none;
        }
      }
      .partners-desc {
        margin-top: 10px;
        font-size: 14px;
        line-height: 19px;
      }
      .partners-swiper{
        margin-top: 24px;
        .swiper{
          margin-right: 16px;
          .partners-img{
            height: 208px;
          }
          .partners-names{
            height: 40px;
            background-color: #FFFFFF;
            border-top: 2px solid @background;
            padding: 0 24px;
            .partners-name{
              font-size: 18px;
              font-weight: 600;
              color: #303133;
              line-height: 40px;
            }
          }
        }
      }
      .swiper-button-next, .swiper-button-prev{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #FFFFFF;
        box-shadow: 0px 6px 18px 0px rgba(38, 38, 38, 0.14);
        background-image: none;
        text-align: center;
        line-height: 48px;
      }
      .swiper-button-prev{
        position: absolute;
        left: -22px;
      }
      .swiper-button-next{
        position: absolute;
        right: -8px;
        --swiper-navigation-color: #8C8C8C;
      }
      .swiper-button-disabled{
        display:none;
      }
    }
  }
  .swiper-container{
    overflow: visible;
  }
  .icon{
    color: #8C8C8C;
  }
  @media only screen and (max-width: 1024px) {
    .partners-content {
      padding: 60px 0 50px 0;
      .partners-list{
        .partners-top {
          .partners-title{
            font-size: 24px;
            line-height: 36px;
          }
          .partners-more{
            font-size: 18px;
            line-height: 26px;
          }
          .arrow{
           display: none;
          }
        }
        .partners-swiper{
          .swiper{
            margin-right: 10px;
            .partners-img{
              height: 158px;
            }
            .partners-names{
              height: 35px;
              padding: 0 18px;
              .partners-name{
                font-size: 16px;
                line-height: 35px;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .partners-content {
      padding: 32px 0 ;
      .partners-list{
        & + .partners-list {
          margin-top: 22px;
        }
        .partners-top {
          .partners-title{
            font-size: 20px;
            color: #232931;
            line-height: 27px;
          }
          .partners-more{
            position: absolute;
            right: -17px;
            font-size: 12px;
            color: #606266;
            line-height: 16px;
            text-decoration: none;
            font-style: normal;
          }
          .arrow{
            display: inline-block;
            color: #8C8C8C;
            margin-left: 10px;
            font-size: 12px;
          }
        }
        .swiper-slide{
            width: 100% !important;
          }
        .partners-swiper{
          .swiper{
            margin-right: 16px;
            .partners-img{
              height: 208px;
            }
          }
        }
      }
      .swiper-button-next, .swiper-button-prev{
        display: none;
      }
    }
    /deep/.public-banner .banner-bg .banner-info .banner-search[data-v-dd598cca] .el-input__inner{
      border-radius: 24px;
    }
  }
}

</style>
