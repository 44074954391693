<template>
  <div class="integration-wrap">
    <public-banner :banner-info="bannerInfo" />
    <div class="box ">
      <div v-for="(item, index) in integrationContent" :key="index" class="contain ">
        <router-link class="content container clearfix" :to="introduction(item.nav_id)">
          <div class="content-left">
            <a class="content-title">{{ item.nav_name_alias }}</a>
            <p class="content-instr" v-html="item.nav_description">{{ item.nav_description }}</p>
          </div>
          <div class="content-right">
            <div class="content-img" :style="`background: url(${item.nav_image}) center center / cover no-repeat`"></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { getIntegrationApi } from '@/api/integration.js'
import { mapActions } from 'vuex'
export default {
  components: {
    PublicBanner
  },
  data() {
    return {
      integrationContent: [],
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      }
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {
    this.getIntegrationList()
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 102 })
      this.bannerInfo.title = data.data.img_title
      this.bannerInfo.img = data.data.img_image
    },
    // 调用主页面接口
    getIntegrationList() {
      getIntegrationApi().then((res) => {
        this.integrationContent = res.data
      })
    },
    // 主页面路由跳转
    introduction(item) {
      if (item === '7') {
        return '/integration/guide'
      } else if (item === '8') {
        return '/integration/product'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.integration-wrap{
  background-color: rgba(0, 0, 0, 0);
  .box{
    .contain{
      &:nth-child(2n) {
        // .content-left {
        //   float: right;
        // }
        // .content-right {
        //   float: left;
        // }
        background-color: @background;
        .content {
          flex-direction: row-reverse;
        }
      }
      .content{
        //flex布局会与浮动发生冲突，如果想要使用flex布局，调换左右顺序可以采用flex-direction:row-reverse
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        align-items: center;
        padding: 64px 0;
        .content-title{
          font-size: 36px;
          color: #303133;
          line-height: 48px;
        }
        .content-instr{
          font-size: 16px;
          margin-top: 48px;
          color: #606266;
          line-height: 21px;
        }
        .content-left{
          width: 48%;
        }
        .content-right{
          width: 48%;
          margin-right: 59px;
        }
        .content-img{
          width: 100%;
          height: 304px;
        }
      }
    }
  }
}
//小屏
@media only screen and (max-width: 1024px) {
  .integration-wrap{
    .box{
      .contain{
        .content{
           padding: 48px 0;
          .content-title{
            font-size: 28px;
            line-height: 36px;
          }
          .content-instr{
            font-size: 14px;
            margin-top: 36px;
            line-height: 22px;
          }
          .content-left{
            width: 42%;
          }
          .content-right{
            width: 42%;
            margin-right: 39px;
          }
          .content-img{
            width: 100%;
          }
        }
      }

    }
  }
}
//移动端
@media only screen and (max-width: 768px) {
  .integration-wrap{
    .box{
      .contain{
        .content{
          padding: 32px 0;
          flex-wrap: wrap;
          .content-title{
            font-size: 22px;
            line-height: 32px;
          }
          .content-instr{
            font-size: 14px;
            margin-top: 24px;
            line-height: 22px;
          }
          .content-left{
            width: 100%;
          }
          .content-right{
            width: 100%;
            margin-right: 0px;
          }
          .content-img{
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

</style>
