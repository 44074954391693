<template>
  <div class="partners-wrap ">
    <div class="partners-title container">
      <h4 class="title">{{ partnersClass.eco_partners }}</h4>
      <p class="content" v-html="partnersClass.eco_partners_description">{{ partnersClass.eco_partners_description }}</p>
      <div class="button">
        <el-button type="info" class="partners-button" @click="learnInfo()">{{ partnersClass.eco_partners_button }}</el-button>
      </div>
    </div>
    <!-- 下拉单和搜索框 -->
    <div v-if="isSearch" class="partners-search">
      <div class="search-class container">
        <el-select v-model="value" placeholder="Category" class="category-search" :class="{'search-bg': value}" @change="filterSearch">
          <el-option
            v-for="item in partnersClass.category_data"
            :key="item.menu_id"
            :label="item.menu_name"
            :value="item.menu_id"
          />
        </el-select>
        <el-select v-model="industryValue" placeholder="Industry" class="industry-search" :class="{'search-bg': value}" @change="filterIndustrySearch(industryValue)">
          <el-option
            v-for="item in partnersClass.industry_data"
            :key="item.menu_id"
            :label="item.menu_name"
            :value="item.menu_id"
          />
        </el-select>
        <el-input
          v-model="keywords"
          class="name-search ellipsis"
          :class="{'search-bg': keywords}"
          placeholder="Enter partner name, application name…"
          clearable
          @change="handleSearch"
        />
        <el-button class="button-search" type="primary" @click="handleSearch">
          {{ LP.lang_dipp_search }}
          <i
            class="iconfont icon-a-search1x icon"
          ></i>
        </el-button>
      </div>
    </div>
    <div class="partners-main">
      <div class="partners-list container">
        <router-link v-for="(item, index) in partnersClass.eco_partners_data" :key="index" :to="`/partners/introduction?company_name=${item.company_name}`" class="partners-items">
          <div class="img">
            <div class="partners-img" :style="`background: #fff url('${item.company_logo}') center center / contain no-repeat`"></div>
          </div>
          <div class="partners-class">
            <h4 class="partners-name ellipsis">{{ item.company_name }}</h4>
          </div>
        </router-link>
      </div>
      <div class="public-pagination container">
        <el-pagination
          v-loading="loading"
          background
          layout="prev, pager, next, jumper"
          :total="total"
          :pager-count="5"
          :page-size="limit"
          @current-change="handlePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { partnersListApi } from '@/api/partners'
export default {
  data() {
    return {
      isSearch: true, // 是否显示搜索栏
      options: [], // 下拉分类框列表数据
      value: '', // categroy下拉分类值
      industryValue: '', // industry下拉分类值
      keywords: '', // 搜索框绑定值
      partnersClass: [], // 合作伙伴列表数据
      selectOptions: [], // 搜索后的合作伙伴列表数据
      total: 0, // 分页总条数
      limit: 0, // 每页显示的最大条数
      loading: false// 页面加载
    }
  },
  watch: {
    // 监听路由变化
    $route(to, from) {
      if (to.query.type !== from.query.type) {
        this.partnersList('', '', this.$route.query.type, '', this.$route.query.type, '')
      }
    }
  },
  created(){
    if(this.$route.query.type == "3"){
      this.$router.push("errorPage/404")
    }
  },
  mounted() {
    this.partnersList('', '', this.$route.query.type, '', this.$route.query.type, '')// partners类别下的数据
  },
  methods: {
    // 按钮跳转
    learnInfo() {
      this.$router.push(this.partnersClass.eco_partners_link)
    },
    // 获取partners类别下的数据
    partnersList(partnerName, category, dhop, page, type, industryValue) {
      this.loading = true
      partnersListApi({
        company_name: partnerName,
        category: category,
        dhop,
        page,
        type,
        industryValue

      }).then(res => {
        this.partnersClass = res.data
        this.total = res.data.count
        this.limit = res.data.limit
        if (dhop === '3') {
          this.isSearch = false
        }
      })
      this.loading = false
    },
    // 搜索按钮
    handleSearch() {
      this.partnersList(this.keywords, this.value, this.$route.query.type, '', this.$route.query.type, this.industryValue)// partners类别下的数据
    },

    // 分页
    handlePage(val) {
      this.partnersList(this.keywords, this.value, this.$route.query.type, val, this.$route.query.type, this.industryValue)// partners类别下的数据
    },
    // categroy下拉菜单点击项，筛选出对应的内容
    filterSearch(value) {
      this.value = value
      this.partnersList('', value, this.$route.query.type, '', this.$route.query.type, this.industryValue)
    },
    // Industry下拉菜单点击项，筛选出对应的内容
    filterIndustrySearch(industryValue) {
      this.industryValue = industryValue
      this.partnersList('', this.value, this.$route.query.type, '', this.$route.query.type, industryValue)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.partners-wrap{
  .partners-title {
    text-align: center;
    background-color: #FFFFFF;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #303133;
      line-height: 48px;
      margin-top: 39px;
    }
    .content {
      font-size: 16px;
      color: #606266;
      line-height: 21px;
      margin: 24px 0;
    }
    .button{
      margin-bottom: 39px;
      .partners-button{
        font-size: 16px;
        font-weight: bold;
        line-height: 21px;
        background: @theme;
        border: none;
      }
    }
  }
  .partners-search{
    padding: 28px 0;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 1px;
    border: 1px solid #E4E7ED;
		z-index: 2;
    .search-class{
      height: 100%;
      display: flex;
      justify-content:center;
      align-items:center;
      .category-search{
        width: 416px;
        font-size: 16px;
        color: #979797;
        line-height: 21px;
        margin-right: 24px;
      }
      .industry-search{
        width: 416px;
        font-size: 16px;
        color: #979797;
        line-height: 21px;
        margin-right: 24px;
      }
      .name-search{
        width: 416px;
        color: #979797;
        margin-right: 24px;
        border-radius: 4px;
        font-size: 16px;
      }
      .button-search{
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        border-radius: 4px;
      }
      /deep/ .el-button{
        background: @theme;
      }
    }
    /deep/ .el-input__inner{
      background: #F4F4F5;
    }
    .search-bg {
      /deep/ .el-input__inner {
        background: #fff;
      }
    }
  }
  .partners-main{
    background-color: #F5F7FA;
    .partners-list{
      display: flex;
      flex-wrap:wrap;
      padding-top: 48px;
      .partners-items{
        width: calc(25% - 12px);
        margin: 0 16px 22px 0;
        .shadow();

        overflow: hidden;
        &:nth-child(4n){
          margin-right: 0;
        }
        &:hover{
          .partners-name{
            color: @theme !important;
          }
        }
        .img{
          height: 207px;
          overflow: hidden;
          &:hover{
            .partners-img{
              .scale();
            }
          }
          .partners-img{
            height: 207px;
            .transition();
          }
        }

        .partners-class{
          height: 40px;
          padding: 0 24px;
          background-color: #FFFFFF;
          border-top: 2px solid @background;
          .partners-name{
            font-size: 18px;
            font-weight: 600;
            color: #303133;
            line-height: 40px;
          }
        }
      }
    }
  }
  .el-button--info{
    font-weight: bold;
  }
  .icon{
    margin-left: 10px;
  }
  @media screen and (max-width: 1024px) {
    .partners-title {
      .title {
        font-size: 24px;
        line-height: 36px;
      }
      .content {
        font-size: 14px;
        color: #606266;
        line-height: 21px;
        margin: 16px 0;
      }
      .button{
        margin-bottom: 29px;
        .partners-button{
          font-size: 14px;
          font-weight: bold;
          line-height: 19px;
        }
      }
    }
    .partners-main{
      .partners-list{
        padding-top: 36px;
        .partners-items{
          width: calc(25% - 8px);
          margin: 0 10px 16px 0;
          .partners-img{
            height: 157px;
          }
          .partners-class{
            height: 35px;
            padding: 0 18px;
            .partners-name{
              font-size: 16px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
    @media screen and (max-width: 768px) {
    .partners-title {
      .title {
        font-size: 24px;
        line-height: 24px;
      }
      .content {
        font-size: 14px;
        color: #606266;
        line-height: 19px;
        margin: 25px 0;
      }
      .button{
        margin-bottom: 24px;
        .partners-button{
          font-size: 16px;
          font-weight: bold;
          line-height: 21px;
        }
      }
    }
    .partners-search{
      position: sticky;
      top: 60px;
      .search-class{
        display: flex;
        flex-wrap: wrap;
        .category-search{
          width: 100%;
          margin-right:0;
        }
        .name-search{
          width: 100%;
          margin: 16px 0;
        }
      }
    }
    .partners-main{
      .partners-list{
        padding: 24px 13px 0 13px;
        .partners-items{
          width: 100%;
          margin: 0 0 22px 0;
          .partners-img{
            height: 208px;
          }
          .partners-class{
            height: 40px;
            padding: 0 24px;
            .partners-name{
              font-size: 18px;
              line-height: 40px;
            }
          }
        }
      }
    }
		/deep/.public-pagination{
		padding: 0 0 48px 0;
		.el-pagination__editor{
			.el-input{
				.el-input__inner{
					min-width: 36px;
					height: 28px;
				}
			}
		}
		.el-pagination__jump{
			display: block;
			margin: 16px 0 0 0;
		}
	}
  }
}
</style>

