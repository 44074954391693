<template>
  <div class="intro-wrap">
    <!-- 上半部分 -->
    <div class="above-bg">
      <section class="container intro-above">
        <!-- 图片及上部右侧 -->
        <section class="img-text">
          <div class="img-box">
            <img class="img logo-img" :src="detail.company_logo" alt="logo" />
          </div>
          <span class="above-right">
            <section class="head-wrap">
              <public-title :title="detail.company_name" class="name-title" />
              <span class="type_wrap">
                <img v-for="(item,index) in detail.partner_user_type" :key="index" :src="item" alt="type_logo" class="type_logo" />
              </span>
            </section>
            <img class="img mobile-logo-img" :src="detail.company_logo" alt="logo" />
            <p class="intro-description" v-html="detail.company_description"></p>
          </span>
        </section>
        <!-- 链接及邮箱 -->
        <section class="url-email">
          <span class="url-box">
            <a :href="detail.company_url" target="_blank">
              <span class="iconfont">&#xe61b;</span>
              <span class="intro-url">{{ detail.company_url }}</span>
            </a>
          </span>
          <span class="email-box">
            <a :href="'Mailto:' + detail.company_email">
              <span class="iconfont last-icon">&#xe626;</span>
              <span class="intro-email">{{ detail.company_email }}</span>
            </a>
          </span>
        </section>
      </section>
    </div>
    <!-- 下半部分 -->
    <div v-if="solutionLength > 0 || successLength > 0" class="down-bg">
      <section class="container intro-down">
        <template v-if="solutionLength > 0">
          <!-- related_solutions及图片 -->
          <public-title :title="detail.lang_related_solutions" class="related-title intro-text" />
          <section class="solution-box" :class="{'box-center': solutionLength <= 1}">
            <div v-for="(item,index) in detail.solution" :key="index" class="solution-self">
              <router-link :to="'/solutions_services/Introduction/' + item.solution_id">
                <div class="img-wrap" :style="`background:url(${item.cover_image}) center center / contain no-repeat`">
                </div>
                <div class="text-wrap">
                  <h2 class="solution-title">{{ item.solution_name }}</h2>
                  <public-label
                    v-for="(item, index) in item.label"
                    :key="index"
                    class-name="solution-label"
                  >{{ item.name }}</public-label>
                  <public-label
                    v-for="(item, index) in item.custom_label"
                    :key="'info-'+index"
                  >{{ item }}</public-label>
                </div>
              </router-link>
            </div>
          </section>
        </template>
      </section>
      <div v-if="successLength > 0">
        <!-- success_stories及下部图文 -->
        <public-title :title="detail.lang_success_stories" class="success-title intro-text" />
        <div class="story-wrap container">
          <div v-for="(item, index) in detail.success_stories" :key="index" class="story-box">
            <h2 class="story-title-mobile">{{ item.title }}</h2>
            <div class="story-img-box">
              <div class="img-box" :style="`background: url(${item.image}) center center / cover no-repeat`"></div>
            </div>
            <div class="story-text">
              <h2 class="story-title">{{ item.title }}</h2>
              <p class="story-description">{{ item.description }}</p>
              <el-button
                class="story-button"
                type="info"
                @click="downloadPdf(item.file)"
              >
                {{ item.lang_download_brochure }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { partnerDetailApi } from '@/api/partners'
import PublicLabel from '@/components/public/PublicLabel.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'

export default {
  components: {
    PublicLabel,
    PublicTitle
  },
  data() {
    return {
      detail: {}, // 接收接口返回的值
      solutionLength: 0, // solution列表数量
      successLength: 0 // success列表数量
    }
  },
  mounted() {
    this.getDetails() // 调用详情页接口的封装
  },
  methods: {
    // 详情页接口封装
    getDetails() {
      partnerDetailApi({
        company_name: this.$route.query.company_name
      }).then(res => {
        this.detail = res.data
        this.solutionLength = res.data.solution.length
        this.successLength = res.data.success_stories.length
      })
    },
    // pdf文件点击加载
    downloadPdf(path) {
      window.open(path)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/color.less';
.intro-wrap{
	.above-bg{
		width: 100%;
		min-height: 373px;
		background: #FFFFFF;
		.intro-above{
			padding-top: 48px;
			.img-text{
				display: flex;
				flex-direction: row;
				align-items: center;
				.img-box {
					flex: 40%;
					max-width: 308px;
					height: 206px;
					display: flex;
					align-items: center;
					box-shadow:0px 6px 18px 0px rgba(214, 214, 214, 0.5);
					.logo-img{
						display: inline-block;
						width: 100%;
					}
				}
				.above-right{
					flex: 60%;
					margin-left: 16px;
					.head-wrap{
						display: flex;
						align-items: center;
					}
					/deep/.name-title{
						margin: 0;
						text-align: left;
						display: contents;
						.title{
							font-size: 32px;
							font-weight: 600;
							color: #303133;
							line-height: 43px;
							margin: 0;
							padding-right: 12px;
						}
					}
					.type_wrap{
						.type_logo{
							height: 28px;
							padding: 0 8px;
						}
					}
					.mobile-logo-img{
						display: none;
					}
					.intro-description{
						padding-top: 22px;
						font-size: 16px;
						color: #606266;
						line-height: 21px;
						min-height: 141px;
					}
				}
			}
			.url-email{
				border-top: 1px solid #ECEEF2;
				padding: 24px 0;
				font-size: 16px;
				margin-top: 48px;
				.iconfont{
					font-size: 24px;
					padding-right: 12px;
					display: inline-block;
					vertical-align: text-top;
				}
				.last-icon{
					padding-left:calc(268 / 1920 * 100%);
				}
			}
		}
	}
	.down-bg{
		width: 100%;
		background: #F5F7FA;
		.intro-down{
			padding-top: 72px;
			padding-bottom: 71px;
			/deep/.intro-text{
				.title{
					text-align: center;
					font-size: 36px;
					color: #303133;
					margin-bottom: 24px;
				}
			}
			/deep/.success-title{
				margin-top: 72px;
			}
			.solution-box{
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				.solution-self{
					width: 32.5%;
					min-height: 390px;
					background-color: #FFFFFF;
					margin-right: 16px;
					&:last-child{
						margin-right: 0;
					}
					.img-wrap{
						height: 237px;
						font-size: 0;
						text-align: center;
						border-bottom: 1px solid #E4E7ED;
						.solution-img{
							max-width: 100%;
							vertical-align: middle;
						}
					}
					.text-wrap{
						padding-left: 20px;
						.solution-title{
							font-size: 16px;
							font-weight: 600;
							color: #303133;
							padding: 20px 0;
						}
						.solution-label{
							margin-right: 10px;
							margin-bottom: 10px;
						}
					}
				}
			}
			.box-center {
				justify-content: center;
			}
		}
		.story-wrap {
			padding-bottom: 72px;
			.story-box {
				display: flex;
				align-items: center;
				& + .story-box {
					margin-top: 16px;
				}
				.story-title-mobile{
					display: none;
				}
				.story-img-box {
					width: 46%;
					.img-box {
						height: 320px;
					}
				}
				.story-text{
					margin: auto 0;
					width: calc(54% - 52px);
					margin-left: 52px;
					.story-title{
						height: 27px;
						font-size: 20px;
						font-weight: bold;
						color: #303133;
						line-height: 27px;
					}
					.story-description{
						font-size: 16px;
						color: #606266;
						line-height: 21px;
						margin: 24px 0;
					}
					.story-button{
						background-color: @theme;
						border-color: @theme;
						font-size: 16px;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 21px;
						&:hover{
							opacity: 0.7;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.above-bg{
			.intro-above{
				padding-top: 30px;
				.img-text	{
					.img-box {
						flex: 100%;
						height: 100%;
						.logo-img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
		.down-bg{
			.intro-down{
				padding: 30px 0;
				/deep/.success-title{
					margin-top: 30px;
				}
			}
		}
	}
	@media screen and (max-width:768px){
		.above-bg {
			min-height: 335px;
			.intro-above{
				padding-top: 24px;
				.img-text	{
					flex-direction: column;
					.img-box {
						display: none;
					}
					.above-right {
						margin-left: 0;
						.type_wrap{
							padding-top: 10px;
						}
						.head-wrap{
							justify-content: center;
							flex-direction: column;
						}
						/deep/.name-title{
							.title{
								font-size: 25px;
								line-height: 26px;
								margin-right: 0;
							}
						}
					}
				}
				.url-email{
					padding: 15px 0 27px;
					font-size: 13px;
					margin-top: 24px;
					display: flex;
					flex-direction: column;
					.email-box{
						padding-top: 15px;
					}
					.last-icon{
						padding-left: 0;
					}
					.iconfont{
						font-size: 18px;
					}
				}
				.img-text .above-right {
					.mobile-logo-img{
						display: block;
						max-width: 308px;
						margin: 24px auto 16px;
					}
					.intro-description{
						font-size: 14px;
						line-height: 19px;
						padding-top: 0;
					}
				}
			}
		}
		.down-bg{
			.intro-down{
				padding: 20px 0 72px;
				/deep/.success-title{
					margin-top: 20px;
				}
				/deep/.intro-text{
					.title{
						font-size: 24px;
						line-height: 32px;
						margin-bottom: 8px;
					}
				}
				.solution-box{
					.solution-self{
						min-height: 290px;
						margin: 8px auto;
						width: 100%;
						.img-wrap{
							height: 191px;
						}
						.text-wrap{
							padding: 0 0 16px 16px;
							.solution-label{
								margin: 0 6px 6px 0;
							}
							.solution-title{
								padding: 14px 0;
								font-size: 18px;
								line-height: 24px;
							}
						}
					}
				}
			}
			.story-wrap {
				padding-bottom: 40px;
				.story-box{
					flex-wrap: wrap;
					.story-title-mobile{
						width: 100%;
						display: block;
						font-size: 18px;
						line-height: 24px;
						text-align: center;
						padding: 16px 0;
					}
					.story-img-box {
						width: 100%;
						.img-box {
							height: 240px;
						}
					}
					.story-text{
						width: 100%;
						margin-left: 0;
						.story-title{
							display: none;
						}
						.story-description{
							padding: 15px 0;
							font-size: 14px;
							line-height: 19px;
							margin: 0;
						}
						.story-button{
							padding: 10px 20px;
							font-size: 16px;
							display: block;
							margin: auto;
						}
					}
				}
			}
		}
	}
}
</style>
