<template>
  <div class="confirm">
    <div class="confirm-box">
      <h4 v-html="downloadAgreement.name"></h4>
      <p v-html="downloadAgreement.content"></p>
      <div class="btn_group">
        <button class="resolve" @click="resolve()">
          <slot></slot>
        </button>
        <button class="reject" @click="close">
          <a>{{ LP.lang_disagree }}</a>
        </button>
      </div>
      <span class="iconfont icon-guanbi2" @click="close"></span>
    </div>
  </div>
</template>

<script>
import { singlePageApi } from '@/api/integration'
export default {
  props: {
    show: {
      type: Boolean
    },
    id: {
      type: String || Number,
      default: null
    },
    itemURL: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      downloadAgreement: {}
    }
  },
  async mounted() {
    await singlePageApi({
      id: 1
    }).then(res => {
      this.downloadAgreement = res.data
    })
  },
  methods: {
    resolve() {
      this.$emit('update:show', false)
      if (this.itemURL === '') {
        alert('No data!')
      } else {
        window.open(this.itemURL, '_blank')
      }
      // 成功存session下载
      if (!sessionStorage.getItem('isEmailTrue')) {
        sessionStorage.setItem('isEmailTrue', true)
      }
    },
    close() {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .confirm-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 70%;
    padding: 50px 0;
    background-color: #fff;
    >span {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 28px;
      color: #666;
      cursor: pointer;
      .transition;
      &:hover {
        transform: rotate(360deg);
      }
    }
     h4 {
      padding: 0 2%;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      padding-bottom: 10px;
    }
    p {
      height: 80%;
      padding-top: 30px;
      overflow: auto;
      overflow-x: hidden;
      padding: 0 60px;
      color: #666;
      margin-bottom: 4%;
    }
    .btn_group {
      text-align: center;
      height: 10%;
      button {
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        margin: 0 20px;
        a,
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 0 10px;
        }
      }
      .resolve {
        background-color: #0e5fac;

        a,
        span {
          color: #fff;
        }
      }
      .reject {
        background-color: #f5f5f5;
      }
    }
  }
}
</style>
