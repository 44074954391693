<template>
  <div class="sdk-wrap ">
    <public-title :title="LP.lang_Download" />
    <div class="select_wrap container">
      <div class="select">
        <ul>
          <li v-for="(item, index) in sdkTabs.children" :id="item.menu_id" :key="index" ref="list" :class="{on: currentTab(item, index)}" @click="getData(item, index)">
            {{ item.menu_name }}
          </li>
        </ul>
        <el-select v-model="value" placeholder="localeMessage.lang_choose_category">
          <el-option v-for="(item, index) in sdkTabs.children" :id="item.menu_id" :key="index" :label="item.menu_name" :value="item.menu_name" @click.native="getData(item, index)" />
        </el-select>
      </div>
    </div>
    <div v-if="!showApp" class="softWare_list container">
      <dl>
        <dt>
          <div>
            <strong>{{ LP.lang_name }}</strong>
            <b>{{ LP.lang_last_update }}</b>
          </div>
        </dt>
        <dd v-for="(item, index) in dataInfo" :key="index">
          <div class="title" @click="changeIndex(index)">
            <p class="ellipsis">{{ item.download_name }}</p>
            <span>{{ item.post_date }}</span>
          </div>
          <el-collapse-transition>
            <div v-show="sdkCur === index" class="sub_detail">
              <div v-if="item.type == 0">
                <a @click="open(item)">{{ LP.lang_Download }}</a>
                <p>
                  <br />
                </p>
                <p v-if="item.md5 && item.md5 !== ''" class="md_sha">
                  MD5:
                  <span>{{ item.md5 }}</span>
                </p>
                <p v-if="item.hash && item.hash !== ''" class="md_sha">
                  SHA256:
                  <span>{{ item.hash }}</span>
                </p>
                <div v-if="item.download_description" v-html="item.download_description"></div>
              </div>

              <div v-else class="sub_new">
                <div v-if="item.software_download.length > 0">
                  <p class="sub_title">{{ item.lang_software_download }}</p>
                  <div class="sub_model">
                    <el-row class="sub_row">
                      <el-col
                        v-for="(software, softwareIndex) in item.software_download"
                        :key="softwareIndex"
                        :xs="24"
                        :sm="24"
                        :md="11"
                        :lg="7"
                        class="sub_col"
                      >
                        <div class="sub_download" @click="open(software)">
                          <span>{{ software.name }}</span>
                          <i class="iconfont icon-xiazai-"></i>
                          <div style="clear: both;"></div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div v-if="item.user_manual.length > 0">
                  <p class="sub_title">{{ item.lang_user_manual }}</p>
                  <div class="sub_model">
                    <el-row class="sub_row">
                      <el-col
                        v-for="(manual, manualIndex) in item.user_manual"
                        :key="manualIndex"
                        :xs="24"
                        :sm="24"
                        :md="11"
                        :lg="7"
                        class="sub_col"
                      >
                        <div class="sub_download" @click="handleDownload(manual)">
                          <span>{{ manual.name }}</span>
                          <i class="iconfont icon-xiazai-"></i>
                          <div style="clear: both;"></div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div v-if="item.other_download.length > 0">
                  <p class="sub_title">{{ item.lang_other_download }}</p>
                  <div class="sub_model">
                    <el-row class="sub_row">
                      <el-col
                        v-for="(other, otherIndex) in item.other_download"
                        :key="otherIndex"
                        :xs="24"
                        :sm="24"
                        :md="11"
                        :lg="7"
                        class="sub_col"
                      >
                        <div class="sub_download" @click="handleDownload(other)">
                          <span>{{ other.name }}</span>
                          <i class="iconfont icon-xiazai-"></i>
                          <div style="clear: both;"></div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div v-if="item.operation_video.length > 0">
                  <p class="sub_title">{{ item.lang_operation_video }}</p>
                  <div class="sub_model">
                    <el-row class="sub_row">
                      <el-col
                        v-for="(operation, operationIndex) in item.operation_video"
                        :key="operationIndex"
                        :xs="24"
                        :sm="24"
                        :md="11"
                        :lg="7"
                        class="sub_col"
                      >
                        <div class="sub_video">
                          <p class="video_title">{{ operation.menu_name }}</p>
                          <div
                            v-for="(child, childIndex) in operation.menu_message"
                            :key="childIndex"
                            class="video_list"
                            @click="handleJump(child.download_url)"
                          >
                            <span>{{ child.name }}</span>
                            <i class="iconfont icon-jiantou-copy"></i>
                            <div style="clear: both;"></div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </dd>
      </dl>
    </div>

    <div v-else class="softwares_app container">
      <el-row :gutter="30">
        <el-col v-for="(item, index) in dataInfo" :key="index" :lg="8" :md="8" :sm="12" :xs="24" class="softwares_app_in">
          <img class="softwares_app_title" :src="item.download_image" alt="" />
          <div class="softwares_app_content">
            <h2 class="ellipsis">{{ item.download_name }}</h2>
            <div class="softwares_app_content_content" v-html="item.download_description">
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <confirm-box v-if="showBox" :id="itemId" :item-u-r-l="itemURL" :show.sync="showBox">
      <span>{{ LP.lang_agree_license_agreement }}</span>
    </confirm-box>
    <div v-show="sdkData.downloadLimit < sdkData.downloadCount" class="public-pagination container">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="sdkData.downloadCount"
        :page-size="sdkData.downloadLimit"
        :current-page="sdkPage"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
import ConfirmBox from '@/components/event/ConfirmBox.vue'
import { sdkDetailApi, hitApi } from '@/api/integration'
function isAssetTypeAnImage(ext) {
  return (
    [
      'png',
      'jpg',
      'jpeg',
      'bmp',
      'gif',
      'webp',
      'psd',
      'svg',
      'tiff',
      'pdf'
    ].indexOf(ext.toLowerCase()) !== -1
  )
}
export default {
  components: {
    PublicTitle,
    ConfirmBox
  },
  data() {
    return {
      value: '', // 移动端下拉值
      sdkData: {}, // 全部信息
      sdkTabs: {}, // tabs数据
      activeTab: 0, // tabs显示index
      dataInfo: [], // 详情数据
      sdkCur: -1, // 列表打开index
      showApp: false, // 显示app列表详情
      showBox: false, // 弹窗
      itemId: '', // 显示弹窗id
      itemURL: '', // 当前项路径
      sdkChild: '',
      sdkPage: 1
    }
  },
  mounted() {
    if (sessionStorage.getItem('sdkPage')) {
      this.sdkPage = +sessionStorage.getItem('sdkPage')
    }
    const page = sessionStorage.getItem('sdkPage') ? sessionStorage.getItem('sdkPage') : 1
    const id = this.$route.query.id ? this.$route.query.id : 2
    const child = this.$route.params.name
    this.getTabsList(id, child, page)
  },
  beforeDestroy() {
    sessionStorage.removeItem('sdkPage')
    sessionStorage.removeItem('isEmailTrue')
  },
  methods: {
    // 获取tab列表
    async getTabsList(id, child, page) {
      await sdkDetailApi({
        id,
        child,
        page
      }).then(res => {
        this.sdkData = res.data
        this.sdkTabs = res.data.downloadTitle
        this.dataInfo = res.data.download
        this.activeTab = res.data.downloadTitle.children.findIndex(item => item.menu_name.toLowerCase() === child.toLowerCase())
        this.value = child
        const menu_special = res.data.downloadTitle.children[this.activeTab].menu_special
        menu_special === '5' ? this.showApp = true : this.showApp = false
      })
    },
    // 当前显示的tab
    currentTab(item, index) {
      return index === this.activeTab
    },
    // tabs切换获取列表信息
    getData(item, index) {
      if (item.link_type === '1') {
        this.$router.push({
          name: 'integrationGuideDownload',
          params: {
            name: item.menu_name
          }
        })
        this.activeTab = index
        this.value = item.menu_name
        sessionStorage.setItem('sdkPage', 1)
        const id = this.$route.query.id ? this.$route.query.id : 2
        this.getTabsList(id, item.menu_name, 1)
      } else {
        // 跳外链
        window.open(item.menu_keywords, '_self')
      }
    },
    // 点击数据展示下拉
    changeIndex(index) {
      if (this.sdkCur === index) {
        this.sdkCur = -1
      } else {
        this.sdkCur = index
      }
    },
    open(val) {
      this.itemId = val.download_id
      this.itemURL = val.download_url
      if (sessionStorage.getItem('isEmailTrue')) {
        this.showBox = false
        const index = this.itemURL.lastIndexOf('.')
        const ext = this.itemURL.substr(index + 1)
        if (isAssetTypeAnImage(ext) === true) {
          const link = document.createElement('a')
          link.href = this.itemURL
          link.setAttribute('target', '_blank')
          link.setAttribute('rel', 'nofollow noopener noreferrer')
          document.body.appendChild(link)
          link.click()
        } else {
          const link = document.createElement('a')
          link.href = this.itemURL
          document.body.appendChild(link)
          link.click()
        }
      } else {
        this.showBox = true
      }
      // 下载量接口
      hitApi({ id: val.download_id })
    },
    handleDownload(val) {
      if (sessionStorage.getItem('isEmailTrue')) {
        this.showBox = false
        const linkUrl = val.download_url
        const index = linkUrl.lastIndexOf('.')
        const ext = linkUrl.substr(index + 1)
        if (isAssetTypeAnImage(ext) === true) {
          const link = document.createElement('a')
          link.href = linkUrl
          link.setAttribute('target', '_blank')
          link.setAttribute('rel', 'nofollow noopener noreferrer')
          document.body.appendChild(link)
          link.click()
        } else {
          const link = document.createElement('a')
          link.href = linkUrl
          document.body.appendChild(link)
          link.click()
        }
      } else {
        this.showBox = true
      }
    },
    handleJump(videoUrl) {
      if (sessionStorage.getItem('isEmailTrue')) {
        this.showBox = false
        const link = document.createElement('a')
        link.href = videoUrl
        link.setAttribute('target', '_blank')
        link.setAttribute('rel', 'nofollow noopener noreferrer')
        document.body.appendChild(link)
        link.click()
      } else {
        this.showBox = true
      }
    },
    // 关闭弹窗
    close() {
      this.showBox = false
    },
    // 切换分页
    handleCurrentChange(val) {
      this.sdkPage = val
      sessionStorage.setItem('sdkPage', val)
      const id = this.$route.query.id ? this.$route.query.id : 2
      const child = this.$route.params.name
      this.getTabsList(id, child, val)
    }
  }
}
</script>

<style lang="less" scoped>
.sdk-wrap {
  background-color: #F5F5F5;
  min-height: 600px;
  padding-bottom: 30px;
  /deep/ .public-title {
    text-align: left;
    padding: 35px 0;
  }
  .select_wrap {
    margin-bottom: 20px;
    border: 1px solid #bfcbd9;
    border-top: none;
    font-size: 14px;
    .select {
      position: relative;
      ul {
        overflow: hidden;
        background-color: #fff;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 0;
          background-color: #bfcbd9;
        }
        li {
          min-width: 130px;
          height: 45px;
          line-height: 45px;
          text-align: center;
          float: left;
          cursor: pointer;
          border-right: 1px solid #bfcbd9;
          border-top: 1px solid #bfcbd9;
          border-bottom: 1px solid #bfcbd9;
          // text-transform: capitalize;
          position: relative;
          z-index: 1;
          margin-bottom: -1px;
          padding: 0 10px;
          &.on {
            background-color: #319BD8;
            color: #fff;
            position: relative;
          }
          &:hover {
            background-color: #319BD8;
            color: #fff;
          }
        }
      }
      .el-select {
        display: none;
        width: 100%;
        margin-bottom: 18px;
        .el-input__inner {
          border: 0 none;
        }
      }
    }
  }
  .softWare_list {
    margin-top: 25px;
    margin-bottom: 55px;
    dl {
      background-color: #fff;

      dt {
        height: 50px;
        line-height: 50px;
        padding: 0 32px;
        background-color: #dadada;
        overflow: hidden;
        div {
          strong {
            float: left;
          }
          b {
            float: right;
            margin-right: 12%;
          }
        }
      }
      dd {
        padding: 0 32px;
        .title {
          border-bottom: 1px solid #eeeeee;
          padding: 20px 0;
          overflow: hidden;
          cursor: pointer;
          p {
            float: left;
            font-size: 14px;
            line-height: 20px;
          }
          span {
            float: right;
            margin-right: 12%;
          }
        }
        .sub_detail {
          top: 50px;
          left: 0;
          z-index: 1;
          background-color: #f5f5f5;
          padding: 50px;
          margin-bottom: 20px;
          > div {
            // padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 20px;
            // border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
          }
          a {
            display: block;
            width: 385px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            margin-top: 25px;
            background-color: @theme;
            color: #fff;
            cursor: pointer;
          }
          .sub_new {
            border: none;
            .sub_title {
              font-size: 24px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 24px;
              letter-spacing: 0px;
              color: #0e5fac;
              text-align: center;
            }
            .sub_model {
              .sub_row {
                width: 100%;
                margin: 21px 0px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .sub_col {
                  max-width: 384px;
                  margin-bottom: 13px;
                  background-color: #fff;
                  .sub_download {
                    display: flex;
                    align-items: center;
                    padding: 12px 16px 10px 15px;
                    > span {
                      display: inline-block;
                      width: 330px;
                      font-size: 14px;
                      font-weight: normal;
                      font-stretch: normal;
                      line-height: 14px;
                      letter-spacing: 0px;
                      color: #333333;
                      float: left;
                    }
                    > i {
                      font-size: 14px;
                      font-weight: bold;
                      float: right;
                      color: #999999;
                    }
                    &:hover {
                      cursor: pointer;
                      > i,
                      > span {
                        color: #0e5fac;
                      }
                    }
                  }
                  .sub_video {
                    .video_title {
                      // width: 330px;
                      padding: 13px 0;
                      text-align: center;
                      font-size: 14px;
                      font-weight: bold;
                      font-stretch: normal;
                      line-height: 24px;
                      letter-spacing: 0px;
                      color: #333333;
                    }
                    .video_list {
                      padding: 10px 16px 10px 15px;
                      font-size: 14px;
                      font-weight: normal;
                      font-stretch: normal;
                      line-height: 24px;
                      letter-spacing: 0px;
                      color: #333333;
                      border-top: 1px solid #e5e5e5;
                      display: flex;
                      align-items: center;
                      > span {
                        display: inline-block;
                        width: 330px;
                        float: left;
                      }
                      > i {
                        float: right;
                        color: #999999;
                      }
                      &:hover {
                        cursor: pointer;
                        color: #0e5fac;
                        > i {
                          color: #0e5fac;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      dd:last-child {
        padding-bottom: 0.1px;
      }
    }
  }
  .md_sha {
    font-size: 14px;
    color: #333;
    word-break: break-all;
    > span {
      color: #337fe5;
    }
  }
  .softwares_app {
    .softwares_app_in {
      height: 630px;
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;
    }
    .softwares_app_title {
      width: 100%;
    }

    .softwares_app_content {
      padding: 30px;
      height: 100%;
      background-color: #fff;
      h2 {
        height: 38px;
        line-height: 38px;
      }
      .softwares_app_content_content {
        line-height: 19px;
        font-size: 14px;
        color: rgb(102, 102, 102);
          img {
            width: 62%;
            overflow: hidden;
            position: absolute;
            left: 50px;
            bottom: 20px;
          }
      }
    }
  }
  /deep/.public-pagination{
		.el-pagination__jump{
			display: block;
			margin: 16px 0 0 0;
		}
	}
   @media screen and (max-width: 768px) {
     /deep/ .public-title {
        padding: 0;
      }
     .select_wrap {
       border: none;
       .select {
         ul {
           display: none;
           li {
             min-width: auto;
             height: 30px;
             line-height: 30px;
             justify-content: space-around;
             align-items: center;
             width: auto;
           }
         }
         .el-select {
           display: block;
         }
       }
     }
     .softWare_list {
        margin-top: 18px;
        margin-bottom: 27px;
        dl {
          dt {
            height: 26px;
            line-height: 26px;
            padding: 0 8px;
            font-size: 14px;
            div {
              strong {
                display: inline-block;
                width: 70%;
              }
              b {
                width: 30%;
                margin-right: 0;
              }
            }
          }
          dd {
            padding: 0 10px;
            .title {
              font-size: 14px;
              padding: 11px 0;
              p {
                width: 65%;
                word-break: break-all;
              }
              span {
                width: 30%;
                margin-right: 0;
              }
            }
            .sub_detail {
              padding: 18px;
              > div {
                padding-bottom: 20px;
                border-bottom: 0 none;
              }
              a {
                display: block;
                width: 192px;
                height: 28px;
                line-height: 28px;
                margin-top: 0;
              }
            }
          }
        }
     }
     .softwares_app {
      .softwares_app_in {
        height: 670px;
      }
     }
     /deep/.public-pagination {
       padding: 24px 0;
     }
  }
}
</style>
