<template>
  <div v-if="view && view.length > 0" class="view-wrap">
    <public-banner :banner-info="bannerInfo" />
    <div class="view-view" :class="$route.query.id !== '22' || $route.query.id !== '12' ? container : ''">
      <h3 class="view-title">{{ view[0].nav_name_alias }}</h3>
      <p class="view-text" v-html="view[0].nav_content"></p>
      <div v-if="view[0].nav_pdf" class="view-button">
        <el-button type="info" class="info-button" @click="moreInfo()">{{ view[0].lang_more_info }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { getIntegrationProductApi } from '@/api/integration.js'
import { mapActions } from 'vuex'
export default {
  components: {
    PublicBanner
  },
  data() {
    return {
      view: {},
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      }
    }
  },
  watch: {
    // 监听路由变化
    $route(to, from) {
      if (to.query.id !== from.query.id) {
        this.getOverview()
      }
    }
  },
  mounted() {
    // this.getBanner()
    this.getOverview()
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    // async getBanner() {
    //   const data = await this.getBannerImg({ num: 172 })
    //   this.bannerInfo.title = data.data.img_title
    //   this.bannerInfo.img = data.data.img_image
    // },
    // 点击查看更多信息
    moreInfo() {
      if (this.view[0].power === '1') {
        this.$router.push('/login')
      } else {
        window.open(this.view[0].nav_pdf)
      }
    },
    // 获取overview接口数据
    getOverview() {
      getIntegrationProductApi({
        id: this.$route.query.id
      }).then(res => {
        this.view = res.data
        this.bannerInfo.img = res.data[0].banner
        this.bannerInfo.title = res.data[0].banner_name
      })
    }
  }
}
</script>

<style lang="less" scoped>
.view-wrap{
  background: #FFFFFF;
  .view-view{
    padding: 72px 0;
    .view-title{
      font-size: 36px;
      color: #303133;
      line-height: 48px;
      text-align: center;
    }
    .view-text{
      margin-top: 24px;
      font-size: 16px;
      color: #606266;
      line-height: 22px;
    }
    .view-button{
      width: 100%;
      text-align: center;
      .info-button{
        margin-top: 79px;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        background-color: #319BD8;
        border-color: #319BD8;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .view-wrap{
    .view-view{
      padding: 36px 0;
      .img-title{
        font-size: 24px;
        line-height: 48px;
        text-align: center;
      }
      .view-text{
        padding: 0 8px;
        margin-top: 16px;
        font-size: 14px;
        color: #606266;
        line-height: 22px;
      }
      .view-button{
        .info-button{
          margin-top: 50px;
          font-size: 16px;
          font-weight: bold;
          line-height: 21px;
        }
      }
    }
  }
}
</style>
